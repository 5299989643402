import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { ChartComponent } from '@components/charts/chart/chart.component';
import { ILifeState } from '@life/models/life-state';
import { ILifeArea } from '@life/models/life-area';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillEditorOptions } from '../../../../../../quill.config';
import { IPeriodicStats } from '@life/models/periodic-stats';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SinglePeriodicStat } from '@life/models/single-periodic-stat';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'hl-tab-about',
  templateUrl: './tab-about.component.html',
  standalone: true,
  imports: [
    NgForOf,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    QuillModule,
    ChartComponent,
    FormsModule,
    JsonPipe,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class TabAboutComponent implements OnInit, OnChanges {
  @Input() element: ILifeState | ILifeArea | ILifeSpecifics;
  @Output() updateDescription = new EventEmitter<string | null>();

  descriptionControl: FormControl<string | null>;

  constructor(private translate: TranslateService) {}
  quillEditorOptions = QuillEditorOptions;

  growthOption = [
    {
      value: 0,
      label: this.translate.instant('DrawerTabAboutGrowthTypeDaily'),
    },
    {
      value: 1,
      label: this.translate.instant('DrawerTabAboutGrowthTypeWeekly'),
    },
    {
      value: 2,
      label: this.translate.instant('DrawerTabAboutGrowthTypeMonthly'),
    },
  ];
  growthType = this.growthOption[0];

  changeGrowthType(option: { value: number; label: string }) {
    this.growthType = option;
  }

  get statsDateFormat() {
    if (this.growthType.value === 2) {
      return 'MMM';
    } else {
      return 'MMM dd';
    }
  }

  get stats() {
    switch (this.growthType.value) {
      case 2:
        return {
          ...this.element.monthlySatisfactionStatistic,
          date: this.setupStats(
            this.element.monthlySatisfactionStatistic?.date
          ),
        } as IPeriodicStats;
      case 1:
        return {
          ...this.element.weeklySatisfactionStatistic,
          date: this.setupStats(this.element.weeklySatisfactionStatistic?.date),
        } as IPeriodicStats;
      case 0:
      default:
        return {
          ...this.element.dailySatisfactionStatistic,
          date: this.setupStats(this.element.dailySatisfactionStatistic?.date),
        } as IPeriodicStats;
    }
  }

  setupStats(stats: SinglePeriodicStat[] = []) {
    const statArr = [];
    for (let i = 0; i < 10; i++) {
      statArr.push(
        stats[i] || {
          fromDate: '',
          toDate: '',
          satisfaction: 0,
        }
      );
    }

    return statArr as SinglePeriodicStat[];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['element']?.currentValue && !!this.descriptionControl && (changes['element']?.currentValue.description !== this.descriptionControl.value)) {

      this.descriptionControl.patchValue(
        changes['element'].currentValue.description
      );
    }
  }

  ngOnInit() {
    this.descriptionControl = new FormControl<string>(
      this.element.description as string
    );

    this.descriptionControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
    ).subscribe((value) => {
      this.updateDescription.emit(value);
    });
  }
}
