<div data-sl="mask" class="max-w-full rounded-2xl bg-white relative">
  <button
    tabindex="-1"
    class="absolute top-1 right-2.5 text-xl text-holismo-grey"
    (click)="handleMaybe(data.disableClose)">
    <span class="fa-solid fa-xmark"></span>
  </button>
  <div class="container h-full text-slate-700 p-8">
    <h2 class="font-bold text-2xl mb-2">
      {{ 'DialogsPricingTableTitle' | translate }}
    </h2>
    <p class="mb-5">{{ data.message }}</p>
    <div class="w-72 m-auto mt-2">
      <hl-pricing-table></hl-pricing-table>
    </div>
  </div>
</div>
