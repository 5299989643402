import { createSelector } from '@ngrx/store';
import { selectLifeActivities$ } from '@life/selectors/life-activity.selectors';
import { selectLifeFilters$ } from '@life/selectors/life-filters.selectors';
import { LifeItem } from '@life/models/life';
import { ILifeArea } from '@life/models/life-area';
import {
  selectLife$,
  selectLifeAreas$,
  selectLifeEntities$, selectLifeUI$,
  selectMaxLifeAreas$,
} from '@life/selectors/life-area.selectors';
import {
  selectLifeStateIds$,
  selectLifeStates$,
  selectLifeStatesEntities$,
} from '@life/selectors/life-state.selectors';
import {
  selectLifeSpecifics$,
  selectLifeSpecificsEntities$,
} from '@life/selectors/life-specifics.selectors';
import * as fromRoot from '@app/reducers';
import { ILifeState } from '@life/models/life-state';
import { ILifeSpecifics } from '@life/models/life-specifics';
import { Dictionary } from '@ngrx/entity';
import {
  isFilterApplied,
  isSearchFilterApplied,
  mapActivitiesFilterValues,
} from '@life/life.utils';
import { ILifeActivity } from '@life/models/life-activity';

const getSpecificsCount = (
  lifeStates: ILifeState[],
  specificEntities: Dictionary<ILifeSpecifics>
) => {
  let count = 0;

  for (const lifeState of lifeStates) {
    count += lifeState.importance;
    for (const lifeSpecificId of lifeState.specificIds) {
      count += specificEntities[lifeSpecificId]
        ? // @ts-ignore
          specificEntities[lifeSpecificId].importance
        : 1;
    }
  }

  return count || 1;
};

export const selectActivitiesSunburstUI$ = createSelector(
  selectLife$,
  selectLifeActivities$,
  selectLifeFilters$,
  fromRoot.selectQueryParams,
  (life, activityList, lifeFilters) => {
    const unassignedActivities = activityList.filter(
      activity => !activity.lifeAreaId
    );

    const lifeArray = [...life];

    if (unassignedActivities.length > 0) {
      lifeArray.push({
        // @ts-ignore
        id: undefined,
        darkColor: '#4d4d4d',
        mediumColor: '#A6A6A6',
        lightColor: '#f1f3f6',
        description: '',
        activityIds: [],
        icon: 'fa-ban',
        importance: 3,
        notes: [],
        satisfaction: 3,
        sortOrder: 0,
        stateIds: [],
        states: [],
        statesHidden: false,
        urgency: 0,
        name: 'Unassigned',
        activities: [],
      });
    }
    return {
      name: '',
      children: lifeArray
        .map(lifeArea => {
          const activities: ILifeActivity[] = activityList
            .map(activity => {
              return {
                ...activity,
                value: activity.importance,
                darkColor: lifeArea.darkColor,
                mediumColor: lifeArea.mediumColor,
                lightColor: lifeArea.lightColor,
                type: 'activity',
                readonly: !!lifeFilters.selectedDate,
              };
            })
            .filter(
              activity =>
                activity.lifeAreaId === lifeArea.id &&
                isFilterApplied(lifeFilters.focus, activity.isFocused) &&
                isFilterApplied(lifeFilters.urgency, activity.urgency) &&
                isFilterApplied(lifeFilters.importance, activity.importance) &&
                isFilterApplied(
                  lifeFilters.actions,
                  mapActivitiesFilterValues(
                    activity.stateIds.length,
                    activity.specificIds.length
                  )
                ) &&
                isFilterApplied(
                  lifeFilters.satisfaction,
                  activity.satisfaction
                ) &&
                isSearchFilterApplied(lifeFilters.search, activity.name)
            )
            .sort((a, b) => a.sortOrder - b.sortOrder);

          return {
            ...lifeArea,
            value: lifeArea.importance,
            type: 'area',
            children: activities,
            readonly: !!lifeFilters.selectedDate,
          };
        })
        .filter(s => {
          return s.children?.length > 0
            ? true
            : isFilterApplied(lifeFilters.focus, undefined) &&
                isFilterApplied(lifeFilters.urgency, s.urgency) &&
                isFilterApplied(lifeFilters.actions, undefined) &&
                isFilterApplied(lifeFilters.importance, s.importance) &&
                isFilterApplied(lifeFilters.satisfaction, s.satisfaction) &&
                isSearchFilterApplied(lifeFilters.search, s.name);
        })
        .sort((a, b) => a.sortOrder - b.sortOrder) as ILifeArea[],
    };
  }
);

export const selectActivitiesPriorityUI$ = createSelector(
  selectLifeActivities$,
  selectLifeEntities$,
  selectLifeFilters$,
  (lifeActivities, lifeAreaEntities, lifeFilters) => {
    const filteredActivities: LifeItem[] = lifeActivities
      .map((activity, i) => ({
        ...activity,
        orderIndex: i,
        radius:
          50 + (100 - (activity.sortOrder * 100) / lifeActivities.length) * 0.4,
      }))
      .filter(
        activity =>
          isFilterApplied(lifeFilters.focus, activity.isFocused) &&
          isFilterApplied(lifeFilters.urgency, activity.urgency) &&
          isFilterApplied(lifeFilters.importance, activity.importance) &&
          isFilterApplied(lifeFilters.satisfaction, activity.satisfaction) &&
          isFilterApplied(
            lifeFilters.actions,
            mapActivitiesFilterValues(
              activity?.stateIds?.length,
              activity?.specificIds?.length
            )
          ) &&
          isSearchFilterApplied(lifeFilters.search, activity.name)
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
      .map(activity => {
        const lifeArea =
          activity.lifeAreaId && lifeAreaEntities[activity.lifeAreaId]
            ? (lifeAreaEntities[activity.lifeAreaId] as ILifeArea)
            : ({
                lightColor: '#eeeeee',
                mediumColor: '#9E9E9E',
                darkColor: '#424242',
              } as ILifeArea);
        return {
          id: activity.id,
          name: activity.name,
          lightColor: lifeArea.lightColor as string,
          mediumColor: lifeArea.mediumColor as string,
          darkColor: lifeArea.darkColor as string,
          radius: activity.radius,
          importance: activity.importance,
          satisfaction: activity.satisfaction,
          urgency: activity.urgency,
          isFocused: activity.isFocused,
          type: 'activity',
          orderIndex: activity.orderIndex,
        };
      });

    return [...filteredActivities]
      .map(item => ({
        ...item,
        sortOrderFactor: (
          100 -
          (item.orderIndex * 100) / lifeActivities.length
        ).toFixed(0),
        readonly: !!lifeFilters.selectedDate,
      }))
      .sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      });
  }
);

export const selectLifeSunburstUI$ = createSelector(
  selectLife$,
  selectLifeStatesEntities$,
  selectLifeStateIds$,
  selectLifeSpecificsEntities$,
  selectLifeFilters$,
  fromRoot.selectQueryParams,
  (
    life,
    lifeStateEntities,
    lifeStateIds,
    lifeSpecificsEntities,
    lifeFilters
  ) => {
    const totalImportance = calculateTotalImportance(life, lifeStateEntities, lifeSpecificsEntities);

    return {
      name: '',
      children: life
        .map(lifeArea => {
          const states = lifeArea.stateIds
            .filter(id => !!lifeStateEntities[id])
            .map(id => {
              const state: ILifeState = lifeStateEntities[id] as ILifeState;
              return {
                ...state,
                darkColor: lifeArea.darkColor,
                mediumColor: lifeArea.mediumColor,
                lightColor: lifeArea.lightColor,
                value: totalImportance[`${lifeArea.id}+${state.id}`],
                type: 'state',
                readonly: !!lifeFilters.selectedDate,
              };
            })
            .sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });

          return {
            ...lifeArea,
            value:
              totalImportance[lifeArea.id],
            states: states,
            type: 'area',
            readonly: !!lifeFilters.selectedDate,
            children: states
              .map(state => {
                const specifics = state.specificIds
                  .filter(id => !!lifeSpecificsEntities[id])
                  .map(id => {
                    const specific = lifeSpecificsEntities[
                      id
                    ] as ILifeSpecifics;
                    return {
                      ...specific,
                      type: 'specific',
                      darkColor: lifeArea.darkColor,
                      mediumColor: lifeArea.mediumColor,
                      lightColor: lifeArea.lightColor,
                      value: specific.importance,
                      sortOrder: specific.sortOrder as number,
                      readonly: !!lifeFilters.selectedDate,
                    };
                  })
                  .filter(s => {
                    return (
                      isFilterApplied(lifeFilters.focus, s.isFocused) &&
                      isFilterApplied(lifeFilters.urgency, s.urgency) &&
                      isFilterApplied(
                        lifeFilters.actions,
                        mapActivitiesFilterValues(s.activityIds.length)
                      ) &&
                      isFilterApplied(lifeFilters.importance, s.importance) &&
                      isFilterApplied(
                        lifeFilters.satisfaction,
                        s.satisfaction
                      ) &&
                      isSearchFilterApplied(lifeFilters.search, s.name)
                    );
                  })
                  .sort((a, b) => {
                    return a.sortOrder - b.sortOrder;
                  });

                return {
                  ...state,
                  specifics: specifics,
                  children: specifics,
                };
              })
              .filter(s => {
                return s.children?.length > 0
                  ? true
                  : isFilterApplied(lifeFilters.focus, s.isFocused) &&
                      isFilterApplied(lifeFilters.urgency, s.urgency) &&
                      isFilterApplied(lifeFilters.importance, s.importance) &&
                      isFilterApplied(
                        lifeFilters.actions,
                        mapActivitiesFilterValues(s.activityIds.length)
                      ) &&
                      isFilterApplied(
                        lifeFilters.satisfaction,
                        s.satisfaction
                      ) &&
                      isSearchFilterApplied(lifeFilters.search, s.name);
              }),
          };
        })
        .filter(s => {
          return s.children?.length > 0
            ? true
            : isFilterApplied(lifeFilters.focus, undefined) &&
                isFilterApplied(lifeFilters.actions, undefined) &&
                isFilterApplied(lifeFilters.urgency, s.urgency) &&
                isFilterApplied(lifeFilters.importance, s.importance) &&
                isFilterApplied(lifeFilters.satisfaction, s.satisfaction) &&
                isSearchFilterApplied(lifeFilters.search, s.name);
        })
        .sort((a, b) => a.sortOrder - b.sortOrder) as ILifeArea[],
    };
  }
);

export const selectLifePriorityUI$ = createSelector(
  selectLifeAreas$,
  selectLifeStates$,
  selectLifeSpecifics$,
  selectLifeFilters$,
  selectMaxLifeAreas$,
  (lifeAreas, lifeStates, lifeSpecifics, lifeFilters, maxLifeAreas) => {
    const filteredAreas: LifeItem[] = lifeAreas
      .map((lifeArea, i) => ({
        ...lifeArea,
        orderIndex: i,
        radius:
          70 + (100 - (lifeArea.sortOrder * 100) / lifeAreas.length) * 0.4,
      }))
      .filter(
        lifeArea =>
          isFilterApplied(lifeFilters.focus, undefined) &&
          isFilterApplied(lifeFilters.actions, undefined) &&
          isFilterApplied(lifeFilters.urgency, lifeArea.urgency) &&
          isFilterApplied(lifeFilters.importance, lifeArea.importance) &&
          isFilterApplied(lifeFilters.satisfaction, lifeArea.satisfaction) &&
          isSearchFilterApplied(lifeFilters.search, lifeArea.name) &&
          !lifeArea.disabled
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
      .map(lifeArea => ({
        id: lifeArea.id,
        name: lifeArea.name,
        lightColor: lifeArea.lightColor as string,
        mediumColor: lifeArea.mediumColor as string,
        darkColor: lifeArea.darkColor as string,
        radius: lifeArea.radius,
        importance: lifeArea.importance,
        satisfaction: lifeArea.satisfaction,
        urgency: lifeArea.urgency,
        isFocused: false,
        type: 'area',
        orderIndex: lifeArea.orderIndex,
      }));
    const findLifeAreaByStateId = (id: string) => {
      return lifeAreas.find((area: ILifeArea) =>
        area.stateIds.some(stateId => stateId === id)
      );
    };
    const findLifeStateBySpecId = (id: string) => {
      return lifeStates.find((state: ILifeState) =>
        state.specificIds.some(specificId => specificId === id)
      );
    };
    const findLifeAreaBySpecId = (id: string) => {
      const state = findLifeStateBySpecId(id) as ILifeState;
      return findLifeAreaByStateId(state.id);
    };

    const filteredStates: LifeItem[] = lifeStates
      .map((lifeState, i) => ({
        ...lifeState,
        orderIndex: lifeAreas.length + i,
        radius:
          45 +
          (100 -
            ((lifeAreas.length + i) * 100) /
              (lifeAreas.length + lifeStates.length)) *
            0.2,
      }))
      .filter(
        lifeState =>
          isFilterApplied(lifeFilters.focus, lifeState.isFocused) &&
          isFilterApplied(lifeFilters.urgency, lifeState.urgency) &&
          isFilterApplied(lifeFilters.importance, lifeState.importance) &&
          isFilterApplied(
            lifeFilters.actions,
            mapActivitiesFilterValues(lifeState.activityIds.length)
          ) &&
          isFilterApplied(lifeFilters.satisfaction, lifeState.satisfaction) &&
          isSearchFilterApplied(lifeFilters.search, lifeState.name) &&
          !findLifeAreaByStateId(lifeState.id)?.disabled
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
      .map(lifeState => ({
        id: lifeState.id,
        name: lifeState.name,
        lightColor: lifeState.lightColor
          ? (lifeState.lightColor as string)
          : (findLifeAreaByStateId(lifeState.id)?.lightColor as string),
        mediumColor: lifeState.mediumColor
          ? (lifeState.mediumColor as string)
          : (findLifeAreaByStateId(lifeState.id)?.mediumColor as string),
        darkColor: lifeState.darkColor
          ? (lifeState.darkColor as string)
          : (findLifeAreaByStateId(lifeState.id)?.darkColor as string),
        radius: lifeState.radius,
        importance: lifeState.importance,
        satisfaction: lifeState.satisfaction,
        urgency: lifeState.urgency,
        isFocused: lifeState.isFocused,
        type: 'state',
        orderIndex: lifeState.orderIndex,
      }));
    const filteredSpecifics: LifeItem[] = lifeSpecifics
      .map((lifeState, i) => ({
        ...lifeState,
        orderIndex: lifeAreas.length + lifeStates.length + i,
        radius:
          30 +
          (100 -
            ((lifeAreas.length + lifeStates.length + i) * 100) /
              (lifeAreas.length + lifeStates.length + lifeSpecifics.length)) *
            0.2,
      }))
      .filter(
        lifeSpecific =>
          isFilterApplied(lifeFilters.focus, lifeSpecific.isFocused) &&
          isFilterApplied(lifeFilters.urgency, lifeSpecific.urgency) &&
          isFilterApplied(
            lifeFilters.actions,
            mapActivitiesFilterValues(lifeSpecific.activityIds.length)
          ) &&
          isFilterApplied(lifeFilters.importance, lifeSpecific.importance) &&
          isFilterApplied(
            lifeFilters.satisfaction,
            lifeSpecific.satisfaction
          ) &&
          isSearchFilterApplied(lifeFilters.search, lifeSpecific.name) &&
          !findLifeAreaBySpecId(lifeSpecific.id)?.disabled
      )
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
      .map(lifeSpecific => ({
        id: lifeSpecific.id,
        name: lifeSpecific.name,
        lightColor: lifeSpecific.lightColor
          ? (lifeSpecific.lightColor as string)
          : (findLifeAreaBySpecId(lifeSpecific.id)?.lightColor as string),
        mediumColor: lifeSpecific.mediumColor
          ? (lifeSpecific.mediumColor as string)
          : (findLifeAreaBySpecId(lifeSpecific.id)?.mediumColor as string),
        darkColor: lifeSpecific.darkColor
          ? (lifeSpecific.darkColor as string)
          : (findLifeAreaBySpecId(lifeSpecific.id)?.darkColor as string),
        importance: lifeSpecific.importance,
        satisfaction: lifeSpecific.satisfaction,
        urgency: lifeSpecific.urgency,
        isFocused: lifeSpecific.isFocused,
        radius: lifeSpecific.radius,
        type: 'specific',
        orderIndex: lifeSpecific.orderIndex,
      }));

    return [...filteredAreas, ...filteredStates, ...filteredSpecifics]
      .map(item => ({
        ...item,
        readonly: !!lifeFilters.selectedDate,
        sortOrderFactor: (
          100 -
          (item.orderIndex * 100) /
            (lifeAreas.length + lifeSpecifics.length + lifeStates.length)
        ).toFixed(0),
      }))
      .sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      });
  }
);

const calculateTotalImportance = (life: any, lifeState: any, lifeSpecific: any) => {
  const importances: any = {}
  life.map((lifeArea: ILifeArea) => {
    importances[lifeArea.id] = lifeArea.importance

    lifeArea.stateIds.map((stateId: string) => {
      if (lifeState[stateId]) {
        importances[lifeArea.id] += lifeState[stateId].importance
        importances[`${lifeArea.id}-${stateId}`] = lifeState[stateId].importance;
        lifeState[stateId].specificIds.map((specificId: string) => {
          if (lifeSpecific[specificId]) {
            importances[lifeArea.id] += lifeSpecific[specificId].importance
            importances[`${lifeArea.id}-${stateId}`] += lifeSpecific[specificId].importance;
          }
        })
      }
    })
  })

  return importances
}
