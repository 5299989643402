<div class="w-full flex flex-col py-3 text-gray-600 gap-4">
  <quill-editor
    data-sl="mask"
    [readOnly]="!!element.readonly"
    class="hl-quill sensitive smartlook-hide"
    placeholder="{{ 'DrawerAboutTabQuillPlaceholder' | translate }}"
    [formControl]="descriptionControl"
    [modules]="quillEditorOptions"></quill-editor>
  <div
    class="w-full flex flex-col rounded-lg overflow-hidden py-3 bg-white gap-y-3">
    <div class="flex items-center justify-between text-gray-400 mx-4">
      <span class="font-medium">{{ 'DrawerTabAboutGrowth' | translate }}</span>
      <button [cdkMenuTriggerFor]="menu">
        <span class="mr-2">{{ growthType.label }}</span>
        <i class="fa-thin fa-chevron-down"></i>
      </button>
    </div>
    <hl-chart
      *ngIf="stats"
      [height]="150"
      [dateFormat]="statsDateFormat"
      [stats]="stats"></hl-chart>
  </div>
</div>

<ng-template #menu>
  <div class="popup-menu w-auto" cdkMenu>
    <button
      *ngFor="let option of growthOption"
      cdkMenuItem
      (click)="changeGrowthType(option)"
      class="popup-menu-btn">
      {{ option.label }}
    </button>
  </div>
</ng-template>
