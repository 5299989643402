import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILifeNote } from '@life/models/life-note';
import { QuillModule } from 'ngx-quill';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { LifeModule } from '@life/life.module';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QuillEditorOptions } from '../../../../../quill.config';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { selectLifeFilters$ } from '@life/selectors';
import { Store } from '@ngrx/store';
import { ConfirmationDialogComponent } from '@app/components';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'hl-note',
  templateUrl: './note.component.html',
  standalone: true,
  imports: [
    QuillModule,
    CdkMenuItem,
    CdkMenuTrigger,
    CdkMenu,
    LifeModule,
    DatePipe,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgClass,
  ],
})
export class NoteComponent implements OnInit {
  @Input() note: ILifeNote;
  @Output() deleteNote = new EventEmitter<string>();
  @Output() editNote = new EventEmitter<ILifeNote>();
  @Output() toggleFocused = new EventEmitter<boolean>();

  noteControl: FormControl<string | null>;
  isEdit = false;
  lifeFilters = this.store.selectSignal(selectLifeFilters$);
  protected readonly quillEditorOptions = QuillEditorOptions;

  constructor(private store: Store, private dialog: Dialog, private translate: TranslateService,) {}
  ngOnInit() {
    this.noteControl = new FormControl(this.note.text);
  }

  save(text: string | null) {
    this.editNote.emit({ ...this.note, text });
    this.isEdit = false;
  }

  cancel() {
    this.noteControl.reset(this.note.text);
    this.isEdit = false;
  }

  promptDeleteNote() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: '600px',
      data: {
        title: this.translate.instant(
          'ConfirmationDialogDeleteNoteTitle'
        ),
        message: this.translate.instant(
          'ConfirmationDialogDeleteNoteMessage'
        ),
      },
    });

    dialogRef.closed.subscribe(res => {
      if (res) {
        this.deleteNote.emit(this.note.id);
      }})

  }
}
