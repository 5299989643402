import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { QuillEditorOptions } from '../../../../../../quill.config';
import { QuillModule } from 'ngx-quill';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ILifeActivity } from '@life/models/life-activity';
import { LifeAreaDropdownComponent } from '@app/activities/components/dropdowns/life-area-dropdown/life-area-dropdown.component';
import { HabitDropdownComponent } from '@app/activities/components/dropdowns/habit-dropdown/habit-dropdown.component';
import { IntervalDropdownComponent } from '@app/activities/components/dropdowns/interval-dropdown/interval-dropdown.component';
import { SessionsDropdownComponent } from '@app/activities/components/dropdowns/sessions-dropdown/sessions-dropdown.component';
import { SessionsQuantityDropdownComponent } from '@app/activities/components/dropdowns/sessions-quantity-dropdown/sessions-quantity-dropdown.component';
import { SessionsTimeDropdownComponent } from '@app/activities/components/dropdowns/sessions-time-dropdown/sessions-time-dropdown.component';
import { RemindersDropdownComponent } from '@app/activities/components/dropdowns/reminders-dropdown/reminders-dropdown.component';
import { TimeOfDayDropdownComponent } from '@app/activities/components/dropdowns/time-of-day-dropdown/time-of-day-dropdown.component';
import { EnergyDropdownComponent } from '@app/activities/components/dropdowns/energy-dropdown/energy-dropdown.component';
import { DoItOnTheSideDropdownComponent } from '@app/activities/components/dropdowns/do-it-on-the-side-dropdown/do-it-on-the-side-dropdown.component';
import { StartEndDropdownComponent } from '@app/activities/components/dropdowns/start-end-dropdown/start-end-dropdown.component';

import { ILifeArea } from '@life/models/life-area';
import { Store } from '@ngrx/store';
import { OgActivityActions } from '@life/actions';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { HabitType } from '@life/models/habit.type';
import { WhenInterval, WhenType } from '@life/models/when.type';
import { DayTimeType } from '@life/models/day-time.type';
import { ChartComponent } from '@app/components';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { SinglePeriodicStat } from '@life/models/single-periodic-stat';
import { IPeriodicStats } from '@life/models/periodic-stats';
import { debounceTime, distinctUntilChanged } from 'rxjs';
@Component({
  selector: 'hl-activity-tab-about',
  standalone: true,
  templateUrl: './activity-tab-about.component.html',
  imports: [
    QuillModule,
    FormsModule,
    TranslateModule,
    LifeAreaDropdownComponent,
    TimeOfDayDropdownComponent,
    EnergyDropdownComponent,
    SessionsTimeDropdownComponent,
    HabitDropdownComponent,
    StartEndDropdownComponent,
    DoItOnTheSideDropdownComponent,
    RemindersDropdownComponent,
    SessionsQuantityDropdownComponent,
    IntervalDropdownComponent,
    SessionsDropdownComponent,
    ReactiveFormsModule,
    JsonPipe,
    ChartComponent,
    NgIf,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    NgForOf,
  ],
})
export class ActivityTabAboutComponent implements OnChanges, OnInit {
  @Input() element: ILifeActivity;
  @Input() lifeAreas: ILifeArea[];
  @Output() updateDescription = new EventEmitter<string | null>();

  descriptionControl: FormControl<string | null>;
  showMore = false;
  quillEditorOptions = QuillEditorOptions;
  growthOption = [
    {
      value: 0,
      label: this.translate.instant('DrawerTabAboutGrowthTypeDaily'),
    },
    {
      value: 1,
      label: this.translate.instant('DrawerTabAboutGrowthTypeWeekly'),
    },
    {
      value: 2,
      label: this.translate.instant('DrawerTabAboutGrowthTypeMonthly'),
    },
  ];
  growthType = this.growthOption[0];

  get statsDateFormat() {
    if (this.growthType.value === 2) {
      return 'MMM';
    } else {
      return 'MMM dd';
    }
  }
  get stats() {
    switch (this.growthType.value) {
      case 2:
        return {
          ...this.element.monthlySatisfactionStatistic,
          date: this.setupStats(
            this.element.monthlySatisfactionStatistic?.date
          ),
        } as IPeriodicStats;
      case 1:
        return {
          ...this.element.weeklySatisfactionStatistic,
          date: this.setupStats(this.element.weeklySatisfactionStatistic?.date),
        } as IPeriodicStats;
      case 0:
      default:
        return {
          ...this.element.dailySatisfactionStatistic,
          date: this.setupStats(this.element.dailySatisfactionStatistic?.date),
        } as IPeriodicStats;
    }
  }

  setupStats(stats: SinglePeriodicStat[] = []) {
    const statArr = [];
    for (let i = 0; i < 10; i++) {
      statArr.push(
        stats[i] || {
          fromDate: '',
          toDate: '',
          satisfaction: 0,
        }
      );
    }

    return statArr as SinglePeriodicStat[];
  }
  constructor(private store: Store, private translate: TranslateService) {}

  changeGrowthType(option: { value: number; label: string }) {
    this.growthType = option;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['element']?.currentValue && !!this.descriptionControl && (changes['element']?.currentValue.description !== this.descriptionControl.value)) {
      this.descriptionControl.patchValue(
        changes['element'].currentValue.description
      );
    }
  }

  ngOnInit() {
    this.descriptionControl = new FormControl<string>(
      this.element.description as string
    );

    this.descriptionControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
    ).subscribe((value) => {
      this.updateDescription.emit(value);
    });
  }

  handleHabitChange(habit: HabitType) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          iWantTo: habit,
        },
        prop: 'iwantto',
        payload: `iWantTo=${habit}`,
      })
    );
  }
  handleLifeAreaChange(area: ILifeArea) {
    this.store.dispatch(
      OgActivityActions.moveActivity({
        activity: this.element,
        lifeAreaId: area.id,
      })
    );
  }

  handleIntervalChange(event: {
    when: WhenType;
    whenInterval?: WhenInterval;
    weekdays?: number[];
    days?: number[];
  }) {
    let query = '';

    if (event.whenInterval) {
      query += `&whenInterval=${event.whenInterval}`;
    }

    if (event.weekdays) {
      query += event.weekdays
        .map((weekday, index) => `${index === 0 ? '&' : ''}weekdays=${weekday}`)
        .join('&');
    }

    if (event.days) {
      query += event.days
        .map((day, index) => `${index === 0 ? '&' : ''}days=${day}`)
        .join('&');
    }

    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          when: event.when,
          weekdays: event.weekdays,
          days: event.days,
          whenInterval: event.whenInterval,
        },
        prop: 'when',
        payload: `when=${event.when}${query}`,
      })
    );
  }
  handleSessionsPerDayChange(sessionsPerDay: number) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          sessionPerDay: sessionsPerDay,
        },
        prop: 'sessionperday',
        payload: `number=${sessionsPerDay}`,
      })
    );
  }
  handleSessionsTimeChange(event: { hours: number; minutes: number }) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          timeRequiredIntervalHours: event.hours,
          timeRequiredIntervalMinutes: event.minutes,
        },
        prop: 'timerequired',
        payload: `hours=${event.hours}&minutes=${event.minutes}`,
      })
    );
  }
  handleQuantitySelection(event: any) {
    this.store.dispatch(
      OgActivityActions.updateActivityQuantity({
        activity: {
          ...this.element,
        },
        ...event,
      })
    );
  }
  handleReminderChange(reminders: string[]) {
    this.store.dispatch(
      OgActivityActions.setActivityReminders({
        activity: {
          ...this.element,
          reminders,
        },
      })
    );
  }
  handlePartOfTheDayChange(dayTime: DayTimeType) {
    this.store.dispatch(
      OgActivityActions.setActivityDayTime({
        activity: {
          ...this.element,
          dayTime,
        },
      })
    );
  }
  handleEnergyChange(energy: number) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          energy,
        },
        prop: 'energy',
        payload: `energy=${energy}`,
      })
    );
  }
  handleOnASideChange(event: boolean) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          canDoOnSide: event,
        },
        prop: 'toggleonside',
        payload: '',
      })
    );
  }

  handleStartDateChange(startDate: string | null) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          startDate,
        },
        prop: 'startend',
        payload: { startDate, endDate: this.element.endDate },
      })
    );
  }
  handleEndDateChange(endDate: string | null) {
    this.store.dispatch(
      OgActivityActions.updateActivity({
        activity: {
          ...this.element,
          endDate,
        },
        prop: 'startend',
        payload: { endDate, startDate: this.element.startDate },
      })
    );
  }


}
