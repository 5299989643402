import { AfterViewInit, Component, HostListener } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  delay,
  filter,
  fromEvent,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@core/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { selectActiveDrawerItem$ } from '@life/selectors';
import { productFruits } from 'product-fruits';
import { OgActivityActions, OgLifeActions } from '@life/actions';
import { Dialog } from '@angular/cdk/dialog';
import { PaymentSuccessDialogComponent } from '@components/dialogs/payment-success-dialog/payment-success-dialog.component';
import { OgUserActions } from '@core/actions/user.actions';
import Smartlook from 'smartlook-client';
import { POSITION } from 'ngx-ui-loader';

@Component({
  selector: 'hl-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  loaded$ = new Subject();
  userLoggedIn: boolean;
  bgsPosition = POSITION.centerLeft;
  drawerOpen = this.store.selectSignal(selectActiveDrawerItem$);
  constructor(
    private auth: AuthService,
    private store: Store,
    private translate: TranslateService,
    private appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: Dialog
  ) {
    Smartlook.init('899a842fd3a2b25b5b1bcf0a79465f1b5c0787de', {
      region: 'eu',
    });

    Smartlook.record({
      forms: false,
      ips: true,
      numbers: false,
      api: true,
    });
    appService.subscribeToNotifications();
    appService.checkForUpdates();
    appService.generateAppUid();
    translate.setDefaultLang('en');
    translate.use('en');

    auth.isAuthenticated$
      .pipe(
        takeUntil(this.loaded$),
        filter(authenticated => !!authenticated),
        switchMap(() =>
          this.auth.user$.pipe(
            take(1),
            filter(u => !!u)
          )
        )
      )
      .subscribe(user => {
        this.userLoggedIn = true;

        if (!user?.email_verified) {
          this.router.navigate(['/verify']);
        } else {
          const paymentStamp =
            this.route.snapshot.queryParamMap.get('paymentSuccess');
          const lsStamp = localStorage.getItem('hl-ts');
          if (!!paymentStamp && paymentStamp === lsStamp) {
            this.dialog.open(PaymentSuccessDialogComponent, {
              maxWidth: '600px',
              data: {
                title: this.translate.instant('PaymentSuccessTitle'),
                message: this.translate.instant('PaymentSuccessMessage'),
              },
            });
          }

          if (location.href.indexOf('holismo-bot') !== -1) {
            return;
          }
          this.store.dispatch(
            OgUserActions.initUser(
              user as {
                life_areas: number;
                user_roles: string[];
                lifewheel_enabled: boolean;
              }
            )
          );

          this.appService.fetchSubscription().subscribe(s => {
            const endDate = s.subscriptions?.data[0].trial_end
              ? new Date(s.subscriptions?.data[0].trial_end * 1000)
              : new Date();
            // @ts-ignore
            const dateDifff = Math.abs(endDate - new Date());

            const diffDays = Math.ceil(dateDifff / (1000 * 60 * 60 * 24));

            // @ts-ignore
            productFruits.init('BetHQNp1CD7bpstV', 'en', {
              ...user,
              username: user?.sub as string,
              props: {
                // @ts-ignore
                isTrial:
                  s.subscriptions?.data[0]?.status === 'trialing'
                    ? 'Yes'
                    : 'No',
                // @ts-ignore
                trialEndDate:
                  s.subscriptions?.data[0]?.status === 'trialing'
                    ? new Date(
                        s.subscriptions?.data[0]?.trial_end * 1000
                      ).toISOString()
                    : null,
                trialEndDays: diffDays,
              },
            });
          });
        }

        // @ts-ignore
        Smartlook.identify(user.sub, {
          email: user?.email,
        });
      });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const hasGLobalCalendarOpen =
      document.querySelectorAll('#filter-calendar').length > 0;
    const target: HTMLElement = event.target as HTMLElement;
    if (
      this.drawerOpen().item &&
      // !target.classList.contains('ql-editor') &&
      target?.getAttribute('role') !== 'menuitem' &&
      !hasGLobalCalendarOpen &&
      !target.hasAttribute('autosizeinput')
    ) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          activeItemId: null,
          activeItemType: null,
          activeTabIndex: null,
        },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  ngAfterViewInit() {
    fromEvent(navigator.serviceWorker, 'message')
      .pipe(debounceTime(200), delay(700))
      .subscribe((event: any) => {
        if (
          this.userLoggedIn &&
          event.data.value == 'updateLife' &&
          event.data.uid !== this.appService.appUid &&
          !document.hasFocus()
        ) {
          this.store.dispatch(OgLifeActions.loadLifeAreas({ redirect: false }));
          this.store.dispatch(OgActivityActions.reloadActivities());
        }
      });
    this.onResize({ target: window });
    // navigator.serviceWorker.addEventListener('message', event => {});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth >= 768) {
      this.bgsPosition = POSITION.centerLeft;
    } else {
      this.bgsPosition = POSITION.bottomCenter;
    }
  }
}
